import { ISelectOption } from "types/selectOption";

export const minTime = (): string => {
  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  const localISOTime = new Date(Date.now() - tzoffset)
    .toISOString()
    .slice(0, new Date().toISOString().lastIndexOf(":"));
  return localISOTime;
};

export function changeTimeZone(date: Date, timeZone: string) {
  if (typeof date === "string") {
    return new Date(
      new Date(date).toLocaleString("en-US", {
        timeZone,
      })
    );
  }

  return new Date(
    date.toLocaleString("en-US", {
      timeZone,
    })
  );
}

export function filterConfigTemplates(data: ISelectOption[]): ISelectOption[] {
  const availableTemplates = [
    "TEMP_CO_BASER",
    "TEMP_CO_BASER_2",
    "TEMP_CO_BASER_3",
    "TEMP_CO_BASER_ADM",
    "TEMP_CO_CENTRAL_ADM",
    "TEMP_CO_SICOPER_ADM",
    "TEMP_CO_SICOPER",
    "TEMP_CO_SICOPER_2",
    "TEMP_CO_CENTRAL",
    "TEMP_CO_ASCOOB",
    "TEMP_Z3",
  ];
  return data.filter((option) => availableTemplates.includes(option.label));
}

export function sampleTemplateVlanAddress(
  value: string,
  templateName: string
): string {
  const templatesDefaultValues = {
    TEMP_CO_BASER: `172.26.${value}.XYZ`,
    TEMP_CO_BASER_2: `172.27.${value}.XYZ`,
    TEMP_CO_BASER_3: `172.29.${value}.XYZ`,
    TEMP_CO_BASER_ADM: `172.28.${value}.XYZ`,
    TEMP_CO_CENTRAL_ADM: `172.23.${value}.XYZ`,
    TEMP_CO_SICOPER_ADM: `172.25.${value}.XYZ`,
    TEMP_CO_SICOPER: `172.24.${value}.XYZ`,
    TEMP_CO_SICOPER_2: `172.25.${value}.XYZ`,
    TEMP_CO_CENTRAL: `172.21.${value}.XYZ`,
    TEMP_CO_ASCOOB: `172.29.${value}.XYZ`,
    TEMP_Z3: `172.30.${value}.XYZ`,
  };
  return templatesDefaultValues[
    templateName as keyof typeof templatesDefaultValues
  ];
}

export function validateVlan(vlanAddress: string): boolean {
  const vlanTest = parseInt(vlanAddress);
  if (isNaN(vlanTest)) return false;
  if (vlanTest < 0 || vlanTest > 255) return false;
  else return true;
}

export function validateSerial(serial: string): boolean {
  const validationArr = serial.split("-");
  if (validationArr.length !== 3) return false;
  else if (validationArr.filter((substr) => substr.length !== 4).length !== 0)
    return false;
  else if (
    validationArr.filter((substr) => substr.match(/^[a-z0-9]+$/i)).length !== 3
  )
    return false;
  else return true;
}

export function createOption(label: string): ISelectOption {
  const option = {
    label: label.toUpperCase(),
    value: {
      serial: label.toUpperCase(),
    },
  };
  return option;
}
